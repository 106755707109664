import { runOnUI } from '../core';
export const prepareUIRegistry = runOnUI(() => {
    'worklet';
    const frameCallbackRegistry = {
        frameCallbackRegistry: new Map(),
        frameCallbackActive: new Set(),
        isFrameCallbackRunning: false,
        runCallbacks() {
            const loop = () => {
                this.frameCallbackActive.forEach((key) => {
                    const callback = this.frameCallbackRegistry.get(key);
                    callback();
                });
                if (this.frameCallbackActive.size > 0) {
                    requestAnimationFrame(loop);
                }
                else {
                    this.isFrameCallbackRunning = false;
                }
            };
            if (!this.isFrameCallbackRunning) {
                requestAnimationFrame(loop);
                this.isFrameCallbackRunning = true;
            }
        },
        registerFrameCallback(callback, callbackId) {
            this.frameCallbackRegistry.set(callbackId, callback);
        },
        unregisterFrameCallback(frameCallbackId) {
            this.manageStateFrameCallback(frameCallbackId, false);
            this.frameCallbackRegistry.delete(frameCallbackId);
        },
        manageStateFrameCallback(frameCallbackId, state) {
            if (frameCallbackId === -1) {
                return;
            }
            if (state) {
                this.frameCallbackActive.add(frameCallbackId);
                this.runCallbacks();
            }
            else {
                this.frameCallbackActive.delete(frameCallbackId);
            }
        },
    };
    global._frameCallbackRegistry = frameCallbackRegistry;
});
